<template>
  <div class="collect" >
      <!-- tab -->
      <div class="collect-tag-nav">
            <div class="collect-tag">
                <div class="collect-tab" :class="{on:navLoaded}">
                    <van-tabs v-model="active" line-height="0" :ellipsis="false" swipe-threshold="1" @change="onChangeTab" ref="tabs">
                        <van-tab title="全部" :badge="allNum"></van-tab>
                        <van-tab title="待归类" :badge="noTagNum"></van-tab>
                        <van-tab title="画板" :badge="tagNum"></van-tab>
                        <van-tab v-if="showItem" :title="tags.label_name" :badge="tags.count"></van-tab>
                        <van-tab v-else title="" badge=""></van-tab>
                    </van-tabs>
                </div>
                <div class="btn_edit" @click="onPageToTags">管理</div>
            </div>
        </div>

      <div class="collect-content" :class="{on :pageLoaded}">
            <!-- list -->
            <div class="collect-list">
                <template v-if="list.length>0 && active==2">
                  <van-list v-model="loading" :finished="finished" :immediate-check="false" loading-text=" " finished-text="我是有底线的"  @load="onLoadList" :offset="100">
                        <div class="list list-tags">
                            <div class="tag" v-for="(item , index) in list" :key="index" :style="{width: (170 / 37.5) + 'rem'}" @click="switchToTab(item.id)">
                                <div calss="tag-imgs" :style="{width: (170 / 37.5) + 'rem', height: (112.5 / 37.5) + 'rem'}">
                                    <template v-if="item.works_imgs[0]">
                                        <van-image class="img-bg1" :src="item.works_imgs[0]" :style="{width: (112.5 / 37.5) + 'rem'}"></van-image>
                                    </template>
                                    <template v-else>
                                        <div class="img-bg1" :style="{width: (112.5 / 37.5) + 'rem', height: (112.5 / 37.5) + 'rem'}"></div>
                                    </template>

                                    <template v-if="item.works_imgs[1]">
                                        <van-image class="img-bg2" :src="item.works_imgs[1]" :style="{width: (54 / 37.5) + 'rem'}"></van-image>
                                    </template>
                                    <template v-else>
                                        <div class="img-bg2" :style="{width: (54 / 37.5) + 'rem', height: (54 / 37.5) + 'rem'}"></div>
                                    </template>

                                    <template v-if="item.works_imgs[2]">
                                        <van-image class="img-bg3" :src="item.works_imgs[2]" :style="{width: (54 / 37.5) + 'rem'}"></van-image>
                                    </template>
                                    <template v-else>
                                        <div class="img-bg3" :style="{width: (54 / 37.5) + 'rem', height: (54 / 37.5) + 'rem'}"></div>
                                    </template>
                                </div>
                                <div class="tag-text">
                                    <p>{{item.label_name}}</p>
                                    <span>{{item.works_num}}</span>
                                </div>
                            </div>
                        </div>
                  </van-list>
                </template>
                <template v-else-if="list.length>0 ">
                    <van-list v-model="loading" :finished="finished" :immediate-check="false" loading-text=" " finished-text="我是有底线的"  @load="onLoadList" :offset="100">
                        <div class="list">
                            <c-waterfall :list="list" :winWidth="winWidth"></c-waterfall>
                        </div>
                    </van-list>
                </template>
                <template v-else>
                    <div class="collect-empty">
                        <c-image iUrl="icon-no_tag" iClass="icon" iWidth="90" iHeight="132"></c-image>
                        <h1>该画板下没有收藏的作品</h1>
                        <h2>赶紧去搜索吧</h2>
                    </div>
                </template>
            </div>
      </div>
      <c-loading :showLoading="!pageLoaded"></c-loading>
  </div>
</template>

<script>
import { Tab, Tabs, List, Image } from "vant";
import CImage from "../../components/CImage";
import CWaterfall from "../../components/CWaterfall.vue";
import { mapState, mapActions } from "vuex";
import { toastLoading, closeToast } from "../../lib/util";
import CLoading from "../../components/CLoading.vue";
export default {
    components: {
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [List.name]: List,
        [Image.name]: Image,
        [CImage.name]: CImage,
        [CWaterfall.name]: CWaterfall,
        [CLoading.name]:CLoading,
    },
    data() {
        return {
            navLoaded: false,
            pageLoaded: false,
            loading: false, //分页加载
            finished: false, //分页全部加载完成

            active: 0,
            winWidth: window.innerWidth,

            list: [],
            page: 1,

            showItem: false,
            tagsId: 0,

            time: null,
        };
    },
    computed: {
        ...mapState({
            fromRoute: (state) => state.fromRouteName,
            tags: (state) => state.collect.labels.label_info,
            tagNum: (state) => state.collect.labels.label_list_num,
            allNum: (state) => state.collect.labels.label_all_num,
            noTagNum: (state) => state.collect.labels.label_none_num,
            collectList: (state) => state.collect.collectData.list,
            totalPages: (state) => state.collect.collectData.total_pages,
        }),
    },
    watch: {
        collectList(val) {
            if (val.length > 0) {
                this.list = this.list.concat(val);
                this.page++;
            } else {
                this.list = [];
            }
        },
    },
    methods: {
        ...mapActions({
            requestLabels: "collect/requestLabels",
            requestCollections: "collect/requestCollections",
        }),
        onPageToTags() {
            this.$router.push({ name: "tags" });
        },
        onChangeTab(e) {
            clearTimeout(this.time);
            this.initData();

            if(this.active < 3) {
                this.showItem = false;
            }
            // toastLoading();
            this.loadCollects().then(() => {
                closeToast();
                this.pageLoaded = true;
            });
        },
        switchToTab(tag_id) {
            this.active = 3;   // 切换到指定栏目
            this.tagsId = tag_id;

            this.onChangeTab();    // 手动触发画板切换逻辑
            this.loadLabel().then(() => {
                this.$refs.tabs.resize();

                this.showItem = true;
            });

        },
        onLoadList() {
            if (this.pageLoaded) {
                this.time = setTimeout(() => {
                    clearTimeout(this.time);
                    if (this.loading) {
                        if (this.page > this.totalPages) this.finished = true;
                        else this.loadCollects();
                    }
                }, 100);
            }
        },
        loadCollects() {
            return new Promise((resolve) => {
                let _id = "";
                if (this.active == 0) _id = "all";
                else if (this.active == 1) _id = "none";
                else if (this.active == 2) _id = "label_all";
                else _id = this.tagsId;

                this.requestCollections({ label_id: _id, p: this.page, }).then(() => {
                    closeToast();
                    this.time = setTimeout(() => {
                        clearTimeout(this.time);
                        this.loading = false;
                    }, 300);
                    resolve();
                });
            });
        },
        loadLabel() {
            return new Promise((resolve) => {
                let _id = "";
                if (this.active == 3){
                    _id = this.tagsId;
                }

                this.requestLabels({ label_id: _id }).then(() => {
                    resolve();
                });
            });
        },
        loadPage() {
            this.active = 0;
            this.initData();

            // toastLoading();

            this.loadCollects().then(() => {
                this.pageLoaded = true;
                this.navLoaded = true;
            });
        },
        initData() {
            this.time = null;

            this.list = [];
            this.page = 1;

            this.pageLoaded = false;
            this.finished = false;
            this.loading = false;
        },
    },
    created() { },
    activated() {
        this.pageLoaded = false;

        this.loadLabel().then(() => {
            this.$refs.tabs.resize();
            if ((this.fromRoute == "detail" && this.$route.params.clickFrom != "nav") || (this.fromRoute == "homepage" && this.$route.params.clickFrom != "nav")) {
                this.pageLoaded = true;

                if (this.list.length == 0) {
                    this.loadPage();
                }
            } else {
                this.showItem = false;
                this.loadPage();
            }
        });
    },
};
</script>

<style>
.van-image__loading {display: none;}
.van-loading__circular {color: #B3B3B3;}
.collect-content{width:100%; height: 100vh; padding-top: 113px; opacity: 0;}
.collect-content.on{opacity: 1;}
.collect-cover{width:100%;height:100%;position:absolute;left:0;top:0;z-index:100}
.collect-tag-nav{padding: 12px 12px 0; width:100%; position: fixed; left: 0; top: 47px; z-index: 200; background: #f5f5f5;}
.collect-tag{background: #fff; border-radius: 4px; overflow: hidden; display: flex; justify-content: space-between; align-items: center;}
.collect-tag .btn_edit{flex: 1; text-align: center; line-height: 54px; font-size: 16px; color: #F85659; box-shadow: -6px 0px 10px 0px rgba(0, 0, 0, 0.1);}
.collect-tab {width:290px; opacity: 0;}
.collect-tab.on{opacity: 1;}
.collect-tab .van-tabs__nav{background: transparent;}
.collect-tab .van-tabs__wrap{height: 54px;}
.collect-tab .van-tabs__nav{padding: 0 8px;}
.collect-tab .van-tab--active .van-tab__text{color: #F85659;}
.collect-tab .van-tab__text{color: #2E2E2E;}
.collect-tab .van-tab{padding:0 8px; flex: none; white-space: normal;}
.collect-tab .van-info{position: relative; top: auto; font-weight: normal; margin: 0; padding: 0; border: none; margin-left: 4px; right: auto; padding: 0; transform: none; border-radius: 0; background: transparent; min-width: auto; color: #B3B3B3; line-height: 19px; font-size: 16px;}
.collect-tab .van-tab__text-wrapper{display: flex; justify-content: center; align-content: center;}
.collect-list{padding-top:12px}
.collect-list .list {padding: 0 12px}
.collect-list .list-tags {display: flex;flex-flow: row wrap;justify-content: flex-start;}
.collect-list .list-tags .tag {border-radius: 4px; overflow-y: hidden; margin-left: 11px; margin-bottom: 12px;}
.collect-list .list-tags .tag:nth-child(2n-1) {margin-left: 0;}
.collect-list .tag .tag-imgs {position: relative;z-index: 1;}
.collect-list .tag .img-bg1{position: absolute;z-index: 1; top: 0; left: 0; background-color: #EBEBEB;}
.collect-list .tag .img-bg2{position: absolute;z-index: 1; top: 0; right: 0; background-color: #EBEBEB;}
.collect-list .tag .img-bg3{position: absolute;z-index: 1; bottom: 0; right: 0; background-color: #EBEBEB;}
.collect-list .tag .tag-text{background: #fff; height: 48px;padding: 14px;line-height: 20px; font-size: 14px;}
.collect-list .tag .tag-text p {float: left;width: 70%;text-wrap: nowrap;text-overflow: ellipsis;overflow: hidden; }
.collect-list .tag .tag-text span {float: right; color: #B3B3B3;}

.collect-empty .icon{margin:40px auto;}
.collect-empty h1{color: #2E2E2E; font-size: 16px; text-align: center; font-weight: normal;}
.collect-empty h2{color:  #B3B3B3; font-size: 14px; text-align: center; margin-top: 8px; font-weight: normal;}
</style>